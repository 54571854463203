<template>
	<footer>
		<div class="blank_100"></div>
		<ul class="tabbar-box">
			
			<router-link to="/index" class="item1" active-class="active"  >
			    <router-link to="/index" class="title" active-class="activeh2">首页</router-link>
			</router-link>


		    <router-link to="/regist" class="item2" active-class="active" >
				<router-link to="/regist" class="title" active-class="activeh2">报名</router-link>
			</router-link>


<!-- 			<router-link to="/netcall" class="item" active-class="active" >
				<div class="pic">
				<router-link to="/netcall" class="icon" active-class="activebc"  >
			<img class="icon" src="@/assets/tabbar/kefu.png" />
			</router-link>
				</div>
				<router-link to="/netcall" class="title" active-class="activeh2">网络咨询</router-link>
			</router-link> -->


			<router-link to="/phocall" class="item3" active-class="active" >
				<router-link to="/phocall" class="title" active-class="activeh2">咨询</router-link>
			</router-link>
				
			<!-- <div class="item4"  @click="navto">我的</div> -->
			<router-link to="/personal" class="item4" active-class="active" @click.native="navto">
				<router-link to="/personal" class="title" active-class="activeh2">我的</router-link>
			</router-link>
			
		</ul>
	</footer>
</template>

<script>
export default{
	data(){
		return{
			isActive:0,
			
		}
	},
	methods:{
	
		goindex(){
			this.isActive = 0
		},
		goregist(){
			this.isActive = 1
			// this.$router.push('/regist')
		
		},
		golearn(){
			this.isActive = 2
			// this.$router.push('/contact')
		
		},
		gopersonal(){
			this.isActive = 3
			// this.$router.push('/personal')
		
		},
		navto(){
	
			 wx.miniProgram.getEnv(function(res){
      				  if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/personal/personal'});
               			}else{
						
						}
      				  });
		}
	},
	computed:{
		
	}
	
}


</script>

<style scoped>
	.tabbar-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		height: 1.1rem;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 999;
	}
	.tabbar-box .item1{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/index.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item1.active{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/index_h.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item2{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/baoming.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item2.active{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/baoming_h.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item3{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/dianhua.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item3.active{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/dianhua_h.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item4{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/my.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}
	.tabbar-box .item4.active{
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 25%;
		background: url(../../assets/tabbar/my_h.png) no-repeat center;
		background-size: 0.42rem;
		background-position-y: 8px;
	}


	.tabbar-box .item1 .title,.tabbar-box .item2 .title,.tabbar-box .item3 .title,.tabbar-box .item4 .title{
		font-size: 0.2rem;
		color: #666666;
		margin-top: 0.4rem;
	}

</style>

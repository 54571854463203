<template>
	<header>
		<div class="header" :style="{background:bgcol?bgcol:'#0372c7'}">
			<template v-if="$route.path == '/index' || $route.path == '/login' || $route.path == '/personal'">
				<router-link to="/" class="link">
					<img class="icon" :src="config.logo" />
				</router-link>
			<div class="my_btn" @click="navToMy">
				<div class="icon">
					<img class="icon_img" src="@/assets/tabbar/my_h.png" alt="">
				</div>
				<div class="text">我的</div>
			</div>
			</template>
			<!-- <template v-else>
				<div @click="$router.back(-1)" class="link">
					<img class="back-icon" src="@/assets/images/back_btn.png" />
				</div>
			</template> -->
			<template v-if="$route.path != '/index'">
				<h1 class="title">{{title}}</h1>
			</template>
<!-- 			<div class="menu" @click="showMenu = !showMenu">
				<img class="btn" :src="showMenu ? require('@/assets/images/menu_close.png') : require('@/assets/images/menu.png')" />
			</div> -->
		</div>
		<!-- <div class="blank_100"></div> -->
		
		<!-- 菜单弹出层 -->
		<div class="mask" v-if="showMenu"></div>
		<nav class="nav" v-if="showMenu">
			<ul class="yiji">
				<li class="item">
					<router-link to="/index" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon1.png" />
						</div>
						<span class="title">首页</span>
					</router-link>
				</li>
				<li class="item">
					<router-link to="/about" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon2.png" />
						</div>
						<span class="title">学校简介</span>
					</router-link>
					<ul class="erji">
						<li class="item">
							<router-link to="/contact" class="items">
								<div class="pic">
									<img class="icon" src="@/assets/nav/icon10.png" />
								</div>
								<span class="title">联系我们</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li class="item">
					<router-link to="/train" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon3.png" />
						</div>
						<span class="title">培训项目</span>
					</router-link>
				</li>
				<li class="item">
					<router-link to="/video" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon11.png" />
						</div>
						<span class="title">公益视频</span>
					</router-link>
				</li>
				<li class="item">
					<router-link to="/news" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon4.png" />
						</div>
						<span class="title">学校动态</span>
					</router-link>
					<ul class="erji">
						<li class="item">
							<router-link to="/teacher" class="items">
								<div class="pic">
									<img class="icon" src="@/assets/nav/icon9.png" />
								</div>
								<span class="title">教师风采</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li class="item">
					<router-link to="/regist" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon5.png" />
						</div>
						<span class="title">网上报名</span>
					</router-link>
				</li>
				<li class="item">
					<router-link to="/learnCenter/index" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon6.png" />
						</div>
						<span class="title">学习中心</span>
					</router-link>
					<ul class="erji">
						<li class="item">
							<router-link to="/learnCenter/index" class="items">
								<div class="pic">
									<img class="icon" src="@/assets/nav/icon9.png" />
								</div>
								<span class="title">我的课程</span>
							</router-link>
						</li>
						<li class="item">
							<router-link to="/simulation/index" class="items">
								<div class="pic">
									<img class="icon" src="@/assets/nav/icon10.png" />
								</div>
								<span class="title">模拟考试</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li class="item">
					<router-link to="/ExaminationCenter/index" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon7.png" />
						</div>
						<span class="title">考试中心</span>
					</router-link>
				</li>
				<li class="item">
					<router-link to="/download" class="items">
						<div class="pic">
							<img class="icon" src="@/assets/nav/icon8.png" />
						</div>
						<span class="title">资料下载</span>
					</router-link>
				</li>
			</ul>
		</nav>
		<!-- 菜单弹出层 -->
	</header>
</template>

<script>
import MessageVue from '../About/Message.vue';
	export default {
		props: [
			'title',
			'bgcol',
			'loginStatus'
		],
		data() {
			return {
				showMenu: false
			}
		},
		computed: {
			config() {
				return this.$store.state.configInfo;
			}
		},
		created() {
			console.log(this.loginStatus);
		
		},
		methods: {
		// 跳转个人中心


		navToMy(){
			wx.miniProgram.getEnv(function(res){
      					 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/my/my'});
               			}else{
						
						}
      				  });
		}
				// const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).catch(error => error);
				// if(confirmResult != 'confirm') return this.$message.info('已取消退出登录');
				// window.localStorage.setItem("userInfo", '');
				// this.$store.commit("saveUserInfo", '');
				// this.userInfo = '';
				// this.$message.success('退出成功！');
			
		},
	
	};
</script>

<style scoped>
	.header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #0372c7;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
	}
	.header .link{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1rem;
	}
	.header .link .icon{
		height: 0.9rem;
	}
	.header .link .back-icon{
		height: 0.28rem;
	}
	.header .title{
		white-space: nowrap;
		font-size: 0.36rem;
		color: #FFFFFF;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
		max-width: 5rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.header .menu{
		width: 1rem;
		height: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header .menu .btn{
		width: 0.3rem;
		height: 0.3rem;
	}
	
	.nav{
		position: fixed;
		top: 1rem;
		right: 0;
		bottom: 1rem;
		z-index: 999;
		background: #ffffff;
		width: 5rem;
		overflow-y: scroll;
	}
	
	.nav .yiji .item{
		padding: 0 0.3rem;
	}
	.nav .yiji .item .items{
		display: flex;
		align-items: center;
		height: 1rem;
	}
	.nav .yiji .item .items .pic{
		width: 0.34rem;
		height: 0.34rem;
	}
	.nav .yiji .item .items .pic .icon{
		max-height: 100%;
	}
	.nav .yiji .item .items .title{
		font-size: 0.26rem;
		color: #333333;
		margin-left: 0.2rem;
	}
	.my_btn{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		font-size: .2rem;
		margin-right: .2rem;
		text-align: center;
	}
	.my_btn .icon .icon_img{
		width: .5rem;
	}
</style>

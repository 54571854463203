<template>
	<div>
		<!-- 公共头部 -->
		<Header :title="title"></Header>
		
		<div class="section1">
			<router-link to="/contact" class="item">联系方式</router-link>
			<router-link to="/message" class="item active">留言反馈</router-link>
		</div>
		<div class="section2">
			<div class="form-box1">
				<div class="item">
					<h2 class="title">您的姓名</h2>
					<div class="input-box">
						<input type="text" placeholder="请填写您的真实姓名" class="input" v-model="name" />
					</div>
				</div>
				<div class="item">
					<h2 class="title">联系电话</h2>
					<div class="input-box">
						<input type="text" placeholder="请填写您的联系电话" class="input" v-model="tel" />
					</div>
				</div>
				<div class="item">
					<h2 class="title">电子邮箱</h2>
					<div class="input-box">
						<input type="text" placeholder="请填写您的邮箱地址" class="input" v-model="email" />
					</div>
				</div>
				<div class="item">
					<h2 class="title">企业名称</h2>
					<div class="input-box">
						<input type="text" placeholder="请填写您的企业名称" class="input" v-model="company" />
					</div>
				</div>
			</div>
			<div class="form-box2">
				<h2 class="title">留言内容</h2>
				<textarea class="textarea" placeholder="请填写您的留言内容" v-model="content"></textarea>
			</div>
			<div class="submit-box">
				<div class="submit" @click="submit">提  交</div>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '',
				name: '',
				tel: '',
				email: '',
				company: '',
				content: ''
			}
		},
		methods: {
			async formSubmit(data) {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/other/message',data);
				this.$toast.close();
				var code = res.code;
				if(code == 1) {
					this.$messageBox.alert(res.data.info, '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.name = '';
							this.tel = '';
							this.email = '';
							this.company = '';
							this.content = '';
						}
					});
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			submit() {
				var flag = false;
				var	warn = '';
				var name = this.name;
				var nameReg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
				var tel = this.tel;
				var telReg = /^1[3456789]\d{9}$/;
				var email = this.email;
				var emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
				var company = this.company;
				var content = this.content;
				var data = {
					name, tel, email, company, content
				}
				if(!nameReg.test(data['name'])){
					warn = '请输入合法的真实姓名！';
				} else if(!telReg.test(data['tel'])){
					warn = '请输入合法的11位手机号！';
				} else if(data['email'] && !emailReg.test(data['email'])){
					warn = '请输入合法的电子邮箱地址！';
				} else if(!data['content']){
					warn = '请输入您的留言内容！';
				} else {
					flag = true;
					this.formSubmit(data);
				}
				if(flag == false) {
					this.$messageBox.alert(warn, '温馨提示');
					return false;
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #F3F3F3;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		flex: 1;
		font-size: 0.28rem;
		color: #969897;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.8rem;
	}
	.section1 .item.active{
		font-size: 0.32rem;
		color: #FFFFFF;
		background: #86cb08;
		position: relative;
	}
/* 	.section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		border-radius: 0.02rem;
		bottom: 0.08rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	}
	 */
	.section2{
		margin-top: 0.3rem;
		margin-bottom: 0.6rem;
	}
	.section2 .form-box1{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.4rem;
	}
	.section2 .form-box1 .item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 0.02rem solid #eeeeee;
	}
	.section2 .form-box1 .item:last-child{
		border-bottom: none;
	}
	.section2 .form-box1 .item .title{
		font-size: 0.28rem;
		color: #333333;
		width: 2.1rem;
		height: 1rem;
		padding-left: 0.3rem;
		display: flex;
		align-items: center;
	}
	.section2 .form-box1 .item .input-box{
		width: 5.4rem;
		height: 1rem;
		display: flex;
		align-items: center;
	}
	.section2 .form-box1 .item .input-box .input{
		display: block;
		width: 100%;
		height: 0.8rem;
		border: none;
	}
	.section2 .form-box2{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.6rem;
	}
	.section2 .form-box2 .title{
		font-size: 0.28rem;
		color: #333333;
		height: 1rem;
		padding-left: 0.3rem;
		display: flex;
		align-items: center;
		border-bottom: 0.02rem solid #eeeeee;
	}
	.section2 .form-box2 .textarea{
		display: block;
		width: 100%;
		height: 2.24rem;
		padding: 0.2rem 0.3rem;
		border: none;
		resize: none;
		
-webkit-user-select:auto; /*webkit浏览器*/  
    user-select:auto;
    -o-user-select:auto;
    -ms-user-select:auto;
	}
	.section2 .submit-box{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .submit-box .submit{
		width: 5.8rem;
		height: 0.8rem;
		background: #c90719;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.32rem;
		color: #ffffff;
	}
</style>